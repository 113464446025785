<template>
  <div class="fiebox" :style="{ width: heightV }">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="预览"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="wrapper" ref="ele">
      <div>
        <iframe width="100%" height="100%" :src="url"></iframe>
      </div>
      <div></div>
    </div>
  </div>
</template>  
<script>

export default {
  name: "previewGsn",
  data() {
    return {
      heightV: "",
      api: "/FileAction!downloadByApp/",
      http: '/api-gsn',
      url: '',
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    type() {
      return this.$route.query.type;
    },
    title() {
      return this.$route.query.title;
    },
  },
  created() {
    if (this.type == 1) {
      this.api = '/FileAction!showImageByApp/'
    }
    this.url = `${this.http}${this.api}${this.id}-${this.title}`
    console.log(this.url)
  },
  mounted() {
    // var heightCss = window.getComputedStyle(this.$refs.ele).height;
    // console.log(heightCss);
    // this.heightV = heightCss;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    }

  }
}
</script>
<style lang="less" scoped>
.fiebox {
  min-width: 100% !important;
  height: 9999px;
  .header {
    background: linear-gradient(to right, #5ca7f0, #2f6edd);
    .header-search {
      height: 44px;
      font-size: 18px;
      color: #f9f9fb;
      text-align: center;
      line-height: 44px;
      background: linear-gradient(to right, #5ca7f0, #2f6edd);
      .van-nav-bar {
        background: linear-gradient(to right, #5ca7f0, #2f6edd);
        .van-nav-bar__content {
          .van-nav-bar__title {
            font-size: 0.4rem;
            font-family: medium;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.wrapper {
  min-width: 100%;
  height: calc(100% - 1rem);
  overflow: auto;
  background-color: #fff;
  // border: solid 1px red;
  > div:nth-of-type(1) {
    height: 100%;
  }
}
</style>